import { html } from 'htm/preact';
import { Router } from 'preact-router';
import { Link } from 'preact-router//match';

import { ctx } from '../index';
import { LanguageSelector } from '../index';
import { countItems } from '../index';

declare var require: any;

function HeaderLogo() {
    const { dispatch } = ctx();
    return html`<div class="header-left"><a class="logo" href="/" title="" onclick=${() => dispatch({ type: 'resetFilters' })}
    aria-label="store logo">
    <img src="${require('/assets/images/d.png')}" alt="Doug Discovery" />
</a>
<a class="action skip content
area" href="#contentarea">
    <span>
        Skip to Content </span>
</a>
</div>`;
}

function HeaderSearch() {

    const { state, dispatch } = ctx();

    return html`<article class="header-search">
    <section class="block block-search">
        <h1 class="block block-title">Search</h1>
        <div class="block block-content">
                <div class="field search">
                    <div class="control">
                        <label class="label sr-only" for="search">
                            <span>Search</span>
                        </label>                       
                        <input onInput=${(e: InputEvent) => { dispatch({ type: 'setSearchQuery', query: (e.target as HTMLInputElement).value }) }} value=${state.search.q} id="search"   type="text" name="q" placeholder="Search entire store here..."
                            class="input-text" maxlength="128" role="combobox" aria-haspopup="false"
                            aria-autocomplete="both" autocomplete="off" aria-expanded="false" />
                        <div id="search_autocomplete" class="search-autocomplete"></div>
                        <div class="nested">
                            <a class="action advanced"
                                href="/advanced"
                                data-action="advanced-search">
                                Advanced Search </a>
                        </div>
                    </div>
                </div>
                <div class="actions">
                    <button onclick=${() => dispatch({ type: 'search' })} type="submit" title="Search Chemical Products" class="action search" aria-label="Search">
                        <span>Search</span>
                    </button>
                </div>
            <ul class="search-links">
                <li><${Link} activeClassName="current" href=${state.search.q.length ? '/catalog' : '/'}>Product Search<//></li>
                <li><${Link} activeClassName="current" href="/advanced">Concept Search<//></li>
                <li><${Link} activeClassName="current" href="/sketcher">Sketcher Search<//></li>                
            </ul>
        </div>
    </section>
</article>`;
}

export default function Header() {

    const { state } = ctx();

    return html`<header class="page-header">
    <div class="header content">
    ${state.user ? html`` : html`<a href="https://www.fluorochem.co.uk"><button class="fluo-back-btn">Back to fluorochem.co.uk</button></a>`}
        <${Router}>
            <${HeaderLogo} default />
            <div path="/" ></div> 
            <div path="/punchout/:id" ></div> 
        </${Router}>

        <aside class="header-right">

            <ul class="header links">
                <style>
                    .location-selector:hover{
                        background-color: #c0c1c2 !important;
                    }
                </style>
                <li class="customer-welcome">                    
                    ${state.user ? html`` :
            html`<${LanguageSelector}/>`}
                </li>
            </ul>

            ${state.user?.catalog_only || state.user?.punchout_id ? html`` : html`<div data-block="minicart" class="minicart-wrapper tooltip">
                <a alt="Quote" class="action showcart showquote" href="/quote"
                    data-bind="scope: 'minicart_content'">                   
                    <span class="counter qty" style="margin-left:-12px">
                        <span class="counter-number">${countItems(state.quote.items)}</span>
                        <span class="counter-label">${countItems(state.quote.items)}</span>
                    </span>
                </a>
                <span class="tooltiptext">View Quote</span>
            </div>`}
            ${state.user?.catalog_only ? html`` : html`<div data-block="minicart" class="minicart-wrapper tooltip">
                <a alt="Cart" class="action showcart" href="/cart"
                    data-bind="scope: 'minicart_content'">
                    <span class="counter qty">
                        <span class="counter-number">${countItems(state.cart.items)}</span>
                        <span class="counter-label">${countItems(state.cart.items)}</span>
                    </span>
                </a>
                <span class="tooltiptext">View Cart</span>
            </div>`}

            <ul class="header links">
                <li class="customer-welcome">
                    <span class="customer-name" role="link" tabindex="0" data-mage-init='{"dropdown":{}}'
                        data-toggle="dropdown" data-trigger-keypress-button="true"
                        data-bind="scope: 'customer'">
                        <button type="button" class="action switch" tabindex="-1"
                            data-action="customer-menu-toggle">
                            <span>Change</span>
                        </button>
                    </span>
                    <nav class="customer-menu" data-target="dropdown">
                        <ul class="header links">
                            ${state.user
            ? (state.user.punchout_id
                ? html``
                : html`<li class="authorization-link"><a href="/account/orders" class="my-account-link">My Account</a></li>`
            )
            : html`<li class="authorization-link"><a href="/login" class="login-link">Sign In</a></li>
                                        <li class="authorization-link"><a href="/register" class="login-link">Sign Up</a></li>`
        }                            
                        </ul>
                    </nav>
                </li>
            </ul>
        </aside>
        <${Router}>
            <${HeaderSearch} default />
            <div path="/" ></div>
            <div path="/punchout/:id" ></div> 
        </${Router}>
    </div>
</header>`;
}